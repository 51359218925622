<template>
	<div>
		<div class="search">
			<slot></slot>
		</div>
		<el-table
		    :data="tableData" 
			:stripe="stripe" 
			:border="border"  
			 size="mini" 
			:fit="fit"
			:showHeader="showHeader" 
			:tree-props="childrenPorps" 
			:highlightCurrentRow="highlightCurrentRow"
			:height="this.$store.state.windowHeight - tableHeight" 
			@selection-change="selectAll"
			>
			<template v-for="(item,index) in columns">
				<!--通过render函数返回的自定义-->
				<template v-if="!!item.render">
					<el-table-column    
						:prop="item.prop"        
						:label="item.label" 
						:width="item.width"
						:header-align="item.headerAlign"
						:align="item.align"
						:minWidth="item.minWidth"
						:fixed="item.fixed"
						:sortable="item.sortable"
					>
						<template slot-scope="scope" >
							<custom :render="item.render" :row="scope.row" :index="scope.$index"/>
						</template>
					</el-table-column>
				</template>
				<!--纯文本-->
				<template v-else>
					<el-table-column 
						:key = "index" 
						:prop="item.prop"  
						:label="item.label" 
						:width="item.width"
						:sortable="item.sortable"
						:minWidth="item.minWidth"
						:header-align="item.headerAlign"
						:align="item.align"
						:type = 'item.type'
						:show-overflow-tooltip="item.tooltip"
						/>
				</template>
			</template>
		</el-table>
		<Paging  @getPage="getPage" :total="total" :pageNo="parms.current" :size="parms.size" />
	</div>
</template>

<script>
import { postFun } from '@/utils/http';
import custom from './custom.vue';
import { requestController } from '@/utils/http'
// import Loading from '../loading.vue';
import store from '@/utils/store';
export default {
	name: 'PubTable',
	components: {
		custom,
		// Loading,
	},
	data() {
		return {
			tableData: [],
			parms:{
				current: 1,
				size: 20 
			},
			total:0
		}
	},
	props: {
	  
		action: {                // 请求路由
			type: String,
			default: () => ''
		},
		params: {                // 请求参数
			type: Object,
			default: () => ({})
		},
		tableHeight: {
			type: Number,
			default: () => 180
		},
		childrenPorps: {
			type: Object,
			default: () => ({
				children: 'productGetVo',
				hasChildren: 'hasChildren'
			})
		},

		data: {
			type: Array,
			default: () => []
		},
		columns: {
			type: Array,
			default: () => []
		},
		stripe: {
			type: Boolean,
			default: () => false
		},
		border: {
			type: Boolean,
			default: () => false
		},
		size: {
			type: String,
			default: () => 'mini'
		},
		fit: {
			type: Boolean,
			default: () => true
		},
		showHeader: {
			type: Boolean,
			default: () => true
		},
		highlightCurrentRow: {
			type: Boolean,
			default: () => true
		},
		isLocationPaging: {
			type: Boolean,
			default: () => false
		},
		isLoading: {
			type: Boolean,
			default: () => false
		}
	},

	created() {
		
	},
	mounted() {
		this.getInfo()
	},
	methods: {
        //获取信息
        getInfo(){	
		  this.parms.param = this.params
		  requestController(this.action, "post", this.parms , res => {
				if(res.code == 1){
					this.tableData = res.data.records
					this.total = res.data.total 
				}
			})
	    },

		//全选
		selectAll(ev){
		   this.$emit("checkboxBtn",ev)
		},

	    //分页
	    getPage(page) {
		   this.parms.size = page.pageSize
           this.parms.current = page.pageNo
		   this.getInfo() 
		},

		//搜索重置
		searchBtn(){
			this.parms.current = 1
			this.getInfo() 
		}
	}
}
</script>
<style scoped>
 .search{
	margin-bottom:10px;
 }
 ::v-deep .el-table--mini .el-table__cell{
    padding: 0;
 }
</style>
