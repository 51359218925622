import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


// 子集路由表
const ROUTERCHILDREN = [
  {
    // 账户管理
    path: 'accounterPackage',
    name: 'accounterPackage',
    tag: '账户管理',
    component: () => import('@/pages/module-accounter/accounter.vue')
  },
  {
    // 部门管理
    path: 'Department',
    name: 'Department',
    tag: '部门管理',
    component: () => import('@/pages/module-department/index.vue')
  },
  {
    // 项目管理
    path: 'AcstProject',
    name: 'AcstProject',
    tag: '汽车项目',
    component: () => import('@/pages/module-Project/index-acst.vue')
  },
  {
    // 项目管理
    path: 'BicycleProject',
    name: 'BicycleProject',
    tag: '单车项目',
    component: () => import('@/pages/module-Project/index-bicycle.vue')
  },
  {
    // 充电订单管理
    path: 'orderPackage',
    name: 'orderPackage',
    tag: '充电订单',
    component: () => import('@/pages/module-order/order-list/index.vue')
  },
  {
    // 充电套餐管理
    path: 'BicycleMeal',
    name: 'BicycleMeal',
    tag: '单车套餐',
    meta: { key: 'meal' },     //  在套餐设置页面为了识别 功率还是套餐
    component: () => import('@/pages/module-charginginfo/index.vue')
  },

  {
    // 充电套餐管理
    path: 'AcstMeal',
    name: 'AcstMeal',
    tag: '汽车套餐',
    component: () => import('@/pages/module-meal/page-acst/index.vue')
  },


  {
    // 充电功率管理
    path: 'Power',
    name: 'Power',
    tag: '充电功率管理',
    meta: { key: 'power' },     //  在套餐设置页面为了识别 功率还是套餐
    component: () => import('@/pages/module-charginginfo/index.vue')
  },
   // 设备管理 - 充电桩
  {
   
    path: 'BicycleEq',
    name: 'BicycleEq',
    tag: '设备管理-单车电桩',
    component: () => import('@/pages/module-device/pageCharging-bicycle/index.vue')
  },

   //
  {
    path: 'AcstEq',
    name: 'AcstEq',
    tag: '设备管理-汽车电桩',
    component: () => import('@/pages/module-device/pageCharging-acst/index.vue')
  },
  {
    // 财务管理 - 项目收入管理
    path: 'Income',
    name: 'Income',
    tag: '财务管理-项目收入管理',
    component: () => import('@/pages/module-Finance/income/index.vue')
  },
  {
    // 财务管理 - 分润管理
    path: 'Dividend',
    name: 'Dividend',
    tag: '财务管理-分润管理',
    component: () => import('@/pages/module-Finance/dividend/index.vue')
  },
  {
    // 财务管理 - 资产管理
    path: 'Property',
    name: 'Property',
    tag: '财务管理-资产管理',
    component: () => import('@/pages/module-Finance/property/index.vue')
  },
  {
    // 财务管理 - 设备服务
    path:'DeviceServe',
    name:'DeviceServe',
    tag:'财务管理-设备服务费',
    component: () => import ('@/pages/module-Finance/device-serve/index.vue')
  }, 
  {
    // 财务管理 - 充值记录管理
    path:'CostRecords',
    name:'CostRecords',
    tag:'财务管理-充值记录管理',
    component:()=> import ('@/pages/module-Finance/costRecords/index.vue')
  },
  {
    // 财务管理 - 设备消费记录
    path:'ConsumerRecords',
    name:'ConsumerRecords',
    tag:'财务管理-设备消费记录',
    component: () => import ('@/pages/module-Finance/consumer-records/index.vue')
  },
  {
    // 角色管理
    path: 'rolePackage',
    name: 'rolePackage',
    tag: '角色管理',
    component: () => import('@/pages/module-role/index.vue')
  },
  {
    // 日志管理
    path: 'Record',
    name: 'Record',
    tag: '日志管理',
    component: () => import('@/pages/module-Record/index.vue')
  },
  // {
  //   // 系统设置
  // }
]

// 创建路由
const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/Index'
    },
    // 登陆
    {
      path: '/Login',
      name: 'Login',
      component: () => import('@/pages/login.vue')
    },
    {
      path:'/ChargeScreen',
      name:'ChargeScreen',
      component:()=>import('@/pages/module-AAscreen/index.vue')
    },
    // 布局
    {
      path: '/Index',
      name: 'Index',
      redirect:'accounterPackage',
      component: () => import('@/layout/index.vue'),
      children: [
        ...ROUTERCHILDREN
      ]
    }
  ]
})

// 前置守卫
router.beforeEach((to, from, next) => {

  let isPathBoolean = false;

  for (let i = 0; i < router.options.routes.length; i++) {
    let item = router.options.routes[i];
    if (to.name != null && to.name == item.name) {

      isPathBoolean = true;
      break;
    } else {
      isPathBoolean = isPathExist(to, item);
      if (isPathBoolean) break;
    }
  }

  if (isPathBoolean) {
    next();
  } else {
    router.push({
      name: 'accounterPackage'
    })
  }
})

function isPathExist(to, item) {
  if (!item.children) return false;
  let isFun = false;
  for (let i = 0; i < item.children.length; i++) {
    let childeItem = item.children[i]
    if (to.name == childeItem.name) {

      return true;
    } else {
      isFun = isPathExist(to, childeItem)
    }
  }
  return isFun;
}

export default router;