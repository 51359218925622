export default {
  state:{
    // 请求处理之后的数据
    screentProjectMapList:[],
    // 高德打点的数据
    screentMapList:[],
    // 打开高德地图上的项目详情 0 关闭 1 打开
    screentIsMapProjectDeail:0,
    // 高德地图项目详情
    screentMapProject:{},
  },
  mutations:{
    // 修改处理后的数据
    setProjectMapList(state,val){
      state.screentProjectMapList = val;
    },
    // 修改高德打点数组
    setScreentMapList(state, val) {
      state.screentMapList = val;
    },
    // 修改高德弹窗是否开启
    setIsMapProjectDeail(state,val){
      state.screentIsMapProjectDeail = val;
    },
    // 修改高德弹窗的信息
    setMapProject(state,val){
      state.screentMapProject = val;
    }
  }
}