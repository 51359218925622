import Vue from 'vue'
import Vuex from 'vuex'
import {conText} from './configText'
import screenStore from '@/pages/module-AAscreen/utils/screen-store'
import {api} from './config'
Vue.use(Vuex);

const state = {
        ...screenStore.state,
        loginStade:false,      //登录页按钮状态
        largeScreen:false,     //大屏的开关 
        pageSize: 20,          //每页请求数量
        windowwidth: 0,        //浏览器的宽度
        windowHeight: 0,       //浏览器的高度
        topBlockHeight: 60,    //顶部块的高度 
        leftBlockWidth: 200,   //左侧块的宽度
        blockNavHeight: 40,    //页签的高度 
        indexPadding: 15,      //主体的边距
        tableEmpty:"数据空空",  //当表格数据为空的时候显示的文字
        tableSize:"small",    //正常(medium) 小(small) 超小(mini)
        routerNavWidth:200,    //路由页面左侧栏的宽度
        ResetrouterNavWidth:0, //动态重置路由页面的高度 
        inputSize:"small",     //正常(medium) 小(small) 超小(mini)
        popSize:null,          //正常(medium) 小(small) 超小(mini)
        selectSize:"medium",   //正常(medium) 小(small) 超小(mini)  
        btnSize:"small",       //正常(medium) 小(small) 超小(mini)
        emptyText:"",          //为空的支付提示只用用于单个
        operator:"",           //操作人 
        userInfo:"",		       //当前登陆人
        emunList:'',           // 路由菜单
        authorsRoleButtons: ['编辑', '删除', '禁用'],
        conText:conText,       //各种文件配置 
		    tableDataLength:0,     //数据长度 
        // 二维码链接
        qrcodeBicycle:`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx241e7fb4019599f1&redirect_uri=${api.codeUrl}/charging-h5/pages/accredit?serialNo=@serialNo&equipment=bicycle&response_type=code&scope=snsapi_base&state=1#wechat_redirect`,
        qrcodeAcst:`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx241e7fb4019599f1&redirect_uri=${encodeURIComponent(`${api.codeUrl}/charging-h5/pages/accredit?serialNo=serialImei&equipment=acst`)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`,
}

const mutations = {
  ...screenStore.mutations,
  // 获取浏览器窗口大小
  setWindowSize(state,val){
    state.windowHeight = val.height || state.windowHeight;
    state.windowWidth = val.width || state.windowWidth;
  },
  // 获取登录人
  setUserInfo(state,val){
	  state.userInfo = val;
  },
  // 设置路由菜单
  setEmunList(state,val){
    state.emunList = val;
  },
}

const store = new Vuex.Store({
      state,
      mutations
})

export default store;