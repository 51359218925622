import axios from 'axios'
import Vue, { del } from 'vue'
import option from './config.js'

// 创建要给新的axios实例 避免和 原本的aioxs冲突
const httpAxios = new axios.create({
  // 创建默认的url
  baseURL: option.api.httpUrl,
  // 超时时间为5秒
  timeout: 5000
})

// 配置 每个请求的content-type的形式
httpAxios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
httpAxios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
httpAxios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

// 重新跳转登录页面
const skipLogin = () => {
  try{
    // 延迟一秒跳转 拦截流程结束再进行跳转页面
    setTimeout(() => {
      setTimeout(() => {
        app.$router.replace({ path: "/Login", query: {} });
      }, 1000);
    })
  }catch{

  }
}

// 添加 请求拦截
// 添加请求拦截器
httpAxios.interceptors.request.use((config) => {
  // 在发送请求之前做些什么
  let token = localStorage.getItem('brjNewChargeTiken');

  if (!token && !config.url.includes(option.api.login) && !config.url.includes('charging-customer-service')) {
    console.log('进入')
    skipLogin();
  } else {
    config.headers['Authorization'] = token;
  }

  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
httpAxios.interceptors.response.use((response) => {
  // 对响应数据做点什么
  if (response.status == 200) {
    if (response.data.code != 1) {
      // 数据请求失败
      // ToKen过期
      if (response.data.exceptionCode == "20015" || response.data.exceptionCode == "20011") {
        skipLogin();
      }

      if (response.data.code == 0) {
        // 没有访问权限或token过期
        if (response.data.exceptionCode == "20015" || response.data.exceptionCode == "20011") {
          console.log('前面已跳转到backLogin,有可能code为很多值');
        } else if (response.data.exceptionCode == '70001') {
          // backLogin();
          Vue.prototype.$message.warning(response.data.exceptionMsg || '系统繁忙');
        } else {
          Vue.prototype.$message.warning(response.data.exceptionMsg || '系统繁忙');
        }
      }
    }
  } else {
    Vue.prototype.$message.warning(response.statusText);
  }

  return response;
}, (error) => {
  // 对响应错误做点什么

  try {
    if (error.response.status == 401) {
      skipLogin();
    } else {
      Vue.prototype.$message.warning('网络繁忙');
    }
    return Promise.reject(error);
  } catch {
    return Promise.reject('请求错误 或无配置接口');
  }

});


//登录的特殊方法
export function login(url, parms, callback) {
  httpAxios.post(url, parms, {}).then(function (res) {
    if (res.data.code == 1) {
      console.log('更换token');
      localStorage.setItem("brjNewChargeTiken", res.data.data);
      callback(res);
    }
  });
}

// post 请求
function postFun(url, parms, callback) {
  return new Promise((resolve, reject) => {
    const loading = Vue.prototype.$loading(
      {
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      }
    );
    httpAxios.post(url, parms).then(function (res) {

      loading.close(res);
      if (res.status == 200) {
        if (res.data.code == 1) {
          callback(res.data);
          resolve(res.data);
        }
      } else {
        reject('error');
      }
    }).catch(function (err) {
      // reject(err);
      loading.close();
    });
  });
}

// put请求
function putFun(url, parms, callback) {
  return new Promise((resolve, reject) => {
    const loading = Vue.prototype.$loading(
      {
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      }
    );
    httpAxios.put(url, parms).then(function (res) {
      loading.close();
      if (res.status == 200) {
        if (res.data.code == 1) {
          callback(res.data);
          resolve(res.data);
        } else {
          reject('error');
        }
      }
    }).catch(function (err) {
      loading.close();
      // reject(err);
    });
  });
}

// get请求
function getFun(url, parms, callback) {
  return new Promise((resolve, reject) => {
    const loading = Vue.prototype.$loading(
      {
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      }
    );
    let parm = ''
    for (let it in parms) {
      parm += "&" + it + '=' + parms[it]
    }
    let newUrl = parm ? url + '?' + parm.substr(1) : url;
    httpAxios.get(newUrl).then(function (res) {
      loading.close();
      callback(res.data);
      resolve(res.data);
    }).catch(function (err) {
      // reject(err);
      loading.close();
    });
  });
}

// del请求
function delFun(url, parms, callback) {
  return new Promise((resolve, reject) => {
    const loading = Vue.prototype.$loading(
      {
        lock: true,
        text: '',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      }
    );
    let parm = ''
    for (let it in parms) {
      parm += "&" + it + '=' + parms[it]
    }
    let newUrl = url + (parm ? '?' + parm.substr(1) : '');
    httpAxios.delete(newUrl).then(function (res) {
      loading.close();
      if (res.status == 200) {
        if (res.data.code == 1) {
          callback(res.data);
          resolve(res.data);
        } else {
          reject('error');
        }
      }
    }).catch(function (err) {
      // reject(err);
      loading.close();
    });
  });
}

// 导出文件请求
function exportFileReq(url, parms, callback) {
  return new Promise((resolve, reject) => {
    httpAxios({
      method: 'post',
      headers: { 'Authorization': localStorage.getItem("brjNewChargeTiken") },
      url: url,
      responseType: 'arraybuffer',
      data: parms,
    }).then(function (res) {
      if (res.status == 200)
        callback(res.data);
      else
        reject('error');
    });
  })
}


/**
 * 请求分支
 * @param { 请求地址 } url 
 * @param { 请求方式 } method 
 * @param { 请求参数 } parms 
 * @param { 请求成功的回调 } callback 
 */
export async function requestController(url, method, parms, callback) {

  // 查找方式是否找到
  let isExist = true;

  let methodArr = [
    {
      key: 'post',
      callback: postFun
    },
    {
      key: 'get',
      callback: getFun
    },
    {
      key: 'delete',
      callback: delFun
    },
    {
      key: 'put',
      callback: putFun
    },
    {
      key: 'export',
      callback: exportFileReq
    }
  ];

  try {
    method = method.toLowerCase();
  } catch {
    throw new Error('没有传入 method')
  }

  for (let index in methodArr) {
    let item = methodArr[index];

    if (method == item.key) {
      isExist = false;
      return await item.callback(url, parms, callback);
      // return;
    }
  }

  if (isExist) {
    throw new Error(`没有找到${method}这个访法`);
  }
}

export default httpAxios;