<template>
  <div class="from-root">
    <el-form :model="model" ref="myForm" label-width="100px" :label-width="labelWidth" :label-position="labelPosition"
      :inline="inline" :size="size" :disabled="disabled" :status-icon="statusIcon" :inline-message="inlineMessage"
      :show-message="showMessage" :hide-required-asterisk="hideRequiredAsterisk" :label-suffix="labelSuffix"
      :validate-on-rule-change="validateOnRuleChange"
    >
      <el-form-item v-for="(item,index) in fromConfig" :label="item.label" :key="index" :prop="item.prop" :rules="item.rules">
        <!-- <component :is="`el-${item.type}`" @click="item.click" @change="item.change" @blur="item.blur" @input="item.input" @clear="item.clear" v-model="model[item.value]"></component> -->
        <el-input :style="item.style" v-if="item.formType=='input'" :type="item.type || 'text'" v-model="item.value" :disabled="item.disabled || false" :maxlength="item.max || Infinity" :minlength="item.min || -Infinity" :show-word-limit="item.showWordLimit || false"
        :placeholder="item.placeholder || ''" :clearable="item.clearable || false" :show-password="item.showPassword || false" :size="item.size || ''" :prefix-icon="item.prefixIcon || ''" :suffix-icon="item.suffixIcon || ''"
        :autosize="item.autosize" @blur="item.blur" @focus="item.focus" @input="item.input" @clear="item.clear"></el-input>

        <!-- {:item.formType == 'select'?item.style:{...treeSelect,item.style}} -->
        <el-select :style="{ ...item.style,...treeSelect }" v-else-if="item.formType == 'select' || item.formType == 'treeSelect'" v-model="item.value" :multiple="item.multiple || false"  :size="item.size || ''" :clearable="item.clearable || false" 
        :collapse-tags="item.collapseTag || false"
        :multiple-limit="item.multipleLimit || 0" :placeholder="item.placeholder || ''" :filterable="item.filterable || false" :allowCreate="item.allowCreate" :filter-method="item.filterMethod" :remote="item.remote"
        :loading="item.loading || false" :remote-method="item.remoteMethod" :loading-text="item.loadingText||'加载中'" :no-match-text="item.noMatchText||'无匹配数据'" :no-data-text="item.noDataText || '无数据'"
        :reserve-keyword="item.reserveKeyword ||false" @change="item.change || placeholder" @clear="item.clea || placeholder" @blur="item.blur || placeholder" @remove-tag="item.removeTag || placeholder"
        >
          <template v-if="item.formType == 'select'" >
            <option v-for="(optionTiem,index) in item.option" :disabled="optionTiem.optionDisabled" :label="optionTiem.option.label" :value="optionTiem.option.value" :key="index"></option>
          </template>          
          <option v-else :disabled="item.optionDisabled" :label="item.option.label" :value="item.option.value">
            <el-tree :data="item.tree.data" :props="item.tree.defaultProps" @node-click="item.tree.handleNodeClick || placeholder"></el-tree>
          </option>
        </el-select>

        <template v-else-if="item.formType == 'radio'">
          <el-radio v-for="(radioItem,index) in item.radioArr" :style="item.style" :key="index" :label="radioItem.label || ''" v-model="item.value" :disabled="radioItem.disabled || false">{{ item.txt }}</el-radio>
        </template>

        <el-input-number :style="item.style" v-else-if="item.formType == 'inputNumber'" v-model="item.value" @change="item.change || placeholder" :min="item.min || -Infinity" :max="item.max || Infinity" :disabled="item.disabled" :step="item.step" :size="item.size"
        :placeholder="item.placeholder" @blur="item.blur || placeholder"></el-input-number>

        <el-switch v-else-if="item.formType == 'switch'" v-model="item.value" :active-text="item.activeText" :inactive-text="item.inactiveText" :active-color="item.activeColor" :inactive-color="item.inactiveColor"
        :disabled="item.disabled" :style="item.style" @change="item.change || placeholder"></el-switch>
        <div v-else>
          抱歉未找到这个类型
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props:{
    fromConfig:{
      // 表格渲染数据
      type:Array,
      default:()=>{}
    },
    model:{
      // 表单绑定的数据
      type:Object,
      default: () => {}
    },
    labelWidth:{
      // 显示的宽度
      type:String,
      default: () => '100px'
    },
    labelPosition:{
      // 表格文字显示的位置
      type:String,
      default:() => 'right'
    },
    inline:{
      // 是否内嵌
      type:Boolean,
      default: () => false
    },
    size:{
      // 控制表单内组件尺寸
      type:String,
      default: () => 'small'
    },
    disabled: {
      // 是否禁用表单
      type:Boolean,
      default: () => false
    },
    statusIcon:{
      // 是否显示校验结果
      type: Boolean,
      default: () => false
    },
    inlineMessage:{
      // 是否以行内形式展示信息
      type: Boolean,
      default: () => false
    },
    showMessage:{
      // 是否显示校验错误信息
      type:Boolean,
      default:()=>true
    },
    hideRequiredAsterisk:{
      // 是否隐藏信号
      type:Boolean,
      default: () => false
    },
    labelSuffix:{
      // 表单标签后缀
      type:String,
      default: () => ''
    },
    validateOnRuleChange:{
      // 规则更改时验证
      type: Boolean,
      default: () => true
    }
  },
  filters:{},
  data() {
    return {
      treeSelect:{
        padding:0,
        height:'100%'
      },
      formName:'myForm'
    };
  },
  methods: { 
    // 获取表单ref
    getFormRef(){
      return this.$refs[this.formName];
    },
    // 提交
    submit(callback){
      this.$refs[this.formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            !!callback && callback();
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
    },
    // 重置
    resetForm() {
      this.$refs[this.formName].resetFields();
    },
    // 占位方法
    placeholder(){
      
    }
  }
}
</script>

<style>

</style>