import Vue from 'vue'
import modelbox from './index.vue'

// 模态框类
class pageModel {
  // 构造方法
  constructor(component = {}, option = {}, callback, closeCallBack = () => { }) {

    // 判断option有没有值
    option.on = option.on || {}

    option.on[''] = ()=>{
      debugger;
    }

    option.on['CloseCallback'] = (data)=>{
      this.pageInstance.closeCallbackFun(data);
    }

    option.on['SetTitle'] = (title) => {
      this.pageInstance.setTitle(title);
    }

    option.on['setHeight'] = (height) => {
      this.pageInstance.setHeight(height);
    }

    option.on['SetPageSize'] = (size) => {
      this.pageInstance.setWidth(size);
    }
    option.on['SetPageActions'] = (btn) => {
      this.pageInstance.setBtn(btn);
    }

    option.on['SetMinWidth'] = (data)=>{
      this.pageInstance.setMinWidth(data);
    }

    option.on['Close'] = (data) => {
      this.pageInstance.close();
      !!callback && callback(data);
    }

    // 创建一个 组件
    this.instance = new Vue({
      el: document.createElement('div'),
      render: function (h) {
        return h(
          modelbox,
          [
            h(
              // 嵌套组件
              component,
              option,
              this.$slots.default
            )
          ]
        );
      }
    });


    // 拿到自身的组件
    this.pageInstance = this.instance.$children[0];

    // 将组件追加至body元素末尾
    document.body.appendChild(this.instance.$el);
  }
}



export default pageModel;