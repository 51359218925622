<script type="text/javascript">
  export default{
    name:'custom',
    functional:true,
    props:{
      row:Object,
      render:Function,
      index:Number
    },
    render:(h,data)=>{
      // 将行数据 与 当前行index 放入一个对象
      const params = {
        row:data.props.row,
        index:data.props.index
      }

      // 将返回给我的render函数 给予一个createElement 和 行数据 个性化 样式
      let render = data.props.render;

      // 将render中的个性化 元素进行渲染页面
      return render(h,params);
    }
  }
</script>