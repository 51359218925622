<template>
  <div class="MODEL-ROOT">
    <div ref="box" :class="{ isNotShow: !isAnmin, isShow: isAnmin }" :style="{ 'min-width': minWidth, 'height': this.height, 'width': width }" id="cneter" class="box">
      <div class="title">{{ title }}</div>
      <div @click="close" class="close"> 
         <i style="font-weight: bold" class="el-icon-close"></i>
      </div>
      <div class="content">
        <slot></slot>
      </div>
      <div class="foolter-button">
        <el-button class="buttomBtn" v-for="(item, index) in buttons" :loading="btnState[item.laoding]" @click.native="item.click()"
          :key="index" :type="item.theme" :icon="item.icon" size="medium">{{ item.text }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      minWidth: "0",
      isAnmin: true,
      buttons: [],
      btnState: {},
      title: "",
      height: "auto",
      width: "auto",
      closeCallback:""
    };
  },
  mounted() {
    this.$nextTick(() => {
      let box = this.$refs.box.getBoundingClientRect();
      this.minWidth = box.width + "px";
    });
  },
  methods: {
    closeCallbackFun(cd=()=>{}){
      this.closeCallback = cd;
    },
    // 关闭
    close() {
      this.isAnmin = false;
      setTimeout(() => {
        this.$el.remove();//移除Dom
      }, 100);
      try{
        this.closeCallback();
      }catch{}
    },
    // 设置高度
    setHeight(height) {
      this.height = height;
    },
    // 设置标题
    setTitle(title) {
      this.title = title;
    },
    // 渲染按钮
    setBtn(btns) {
      this.buttons = btns;
      // 判断是否有加载状态
      btns.forEach((item) => {
        if (item.laoding) {
          this.btnState[item.laoding] = false;
        }
      });
    },
    // 修改宽度
    setWidth(width) {
      if (isNaN(width)) {
        this.width = width;
        this.minWidth = this.width;
      } else {
        this.width = width + "px";
        this.minWidth = this.width;
      }
    },
    // 修改最小宽度
    setMinWidth(minWdith) {
      this.$nextTick(() => {
        this.minWidth = isNaN(minWdith) ? minWdith : `${minWdith}px`;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>