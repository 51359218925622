import store from '@/utils/store'
import Router from './route'
import citYvalue from './city'
import * as XLSX from 'xlsx'
// 监听对应dom改变的方法
// 创建WeakMpa用于存放dom和回调 weakMap 不会造成内存溢出
const weakMap = new WeakMap();
// 创建全局监视者
const resizeObserve = new ResizeObserver(entries => {
  // 找到对应的dom 将制度数据丢过去
  for (let index in entries) {
    let entrie = entries[index];
    // 拿到对应数据
    let obj = weakMap.get(entrie.target);

    // 如果没有回调就会报错
    if (!!!obj.callback) {
      new Error('reset指令必需传递一个回调');
    } else {

      let key = '';

      // 默认borderBoxSize
      if (!!!obj.arg) {
        key = 'borderBoxSize';
      } else {
        key = obj.arg;
      }

      obj.callback(entrie[key]);
    }
  }
});

// 工具类
/**
 * date dateTime 
 * @tag 获取指定时间 支持属性： yyyy-MM-dd HH:mm:ss ms week 
 * @param { 时间对象 } date 
 * @param { 转换时间的格式 } format 
 * @param { 是否补零 } isPad 
 */
export function getDate(date, format, isPad) {

  // 将时间进行归一化
  function _dateFormat(format) {

    if (typeof format == 'Funtion') {
      return format;
    }

    if (format == 'date') {
      format = 'yyyy-MM-dd';
    }

    if (format == 'date') {
      format = 'yyyy-MM-dd HH:mm:ss';
    }

    const formatFun = (dateInfo) => {
      const { yyyy, MM, dd, HH, mm, ss, ms, week } = dateInfo;

      return format.replaceAll('yyyy', yyyy)
        .replaceAll('MM', MM)
        .replaceAll('dd', dd)
        .replaceAll('HH', HH)
        .replaceAll('mm', mm)
        .replaceAll('ss', ss)
        .replaceAll('ms', ms)
        .replaceAll('week', week)
    }

    return formatFun;
  }


  let dateFormatFun = _dateFormat(format);

  let week = ["日", "一", "二", "三", "四", "五", "六"];

  let dateInfo = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() * 1 + 1,
    dd: date.getDate(),
    HH: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    ms: date.getTime(),
    week: week[date.getDay()]
  }

  // 补零
  if (isPad) {

    let keyArr = Object.keys(dateInfo);

    for (let key in dateInfo) {
      if (key == 'week') continue;
      let len = key.length;
      dateInfo[key] = (dateInfo[key] + '').padStart(len, '0')
    }
    // console.log('date',dateInfo)
  }

  return dateFormatFun(dateInfo);
}

/**
 * 设置方法的this指向
 * @param { 对象this } _this 
 * @param { 方法名字 } methodArr 
 */
export function setMethod(_this, ...methodArr) {

  // 判断是否为一个数组
  if (Array.isArray(methodArr[0])) {
    methodArr = methodArr[0];
  }

  methodArr.forEach(item => {
    try {
      _this[item] = _this[item].bind(_this);
    } catch {
      throw new Error('没有找到' + item + '这个方法')
    }
  })
}

/**
 * 单列模式
 * @param { 类名 } className 
 * @returns 返回类对象 
 */
export function singleton(className) {
  let ins;

  return new Proxy(className, {
    construct(target, args) {
      if (!ins) {
        ins = new target(...args);
      }

      return ins;
    }
  })
}

/**
 * @tag 下载office文件：xls，xlsx，doc，docx，pdf
 * @param { 文件流 } stream 
 * @param { 文件名称 } name 
 * @param { 文件后缀 } suffix 
 */
export const downloadFile = (stream, name, suffix) => {
  const DOWNLOAD_TYPE_MAP = {
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf'
  };
  if (!DOWNLOAD_TYPE_MAP[suffix]) {
    throw new Error('请传入文件下载的格式后缀，eg：xls，xlsx，doc，docx，pdf');
  }
  const blob = new Blob([stream], {
    type: DOWNLOAD_TYPE_MAP[suffix],
  });
  const fileName = `${name}.${suffix}`;

  let link = document.createElement('a');
  document.body.appendChild(link);
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

//监听扫码枪的事件
export function codeGun() {
  return new Promise((resolve, reject) => {
    // 监听按键
    var code = '';
    var lastTime, nextTime; // 上次时间、最新时间
    var lastCode, nextCode; // 上次按键、最新按键
    document.onkeydown = function (e) {
      // 获取按键
      if (window.event) { // IE
        nextCode = e.keyCode;
      } else if (e.which) { // Netscape/Firefox/Opera
        nextCode = e.which;
      }
      // 如果触发了回车事件(扫码结束时间)
      if (nextCode === 13) {
        if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有
        // this.codeFind(code) // 获取到扫码枪输入的内容，做别的操作
        console.log("数据", code)
        code = '';
        lastCode = '';
        lastTime = '';
        return;
      }
      nextTime = new Date().getTime(); // 记录最新时间
      if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
        code += e.key; // 执行叠加操作
      }
      // 如果有上次时间及上次按键
      if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
        code = e.key;
      } else if (lastCode && lastTime) {
        code += e.key;
      }
      lastCode = nextCode;
      lastTime = nextTime;
      console.log('last code', lastCode, code);
      resolve(code);
    };
  });
}

/**
 * @tag 查找对应数据的目标值
 * @param { 需要查找的数组 } arr 
 * @param { 目标值 } target 
 * @returns 返回查找到的数组
 */
export function checkTarget(arr,target){
    for (let obj of arr) {
        // 如果当前元素是对象，则递归调用isRouteEmun函数
        if (typeof obj === 'object') {
          let result = this.isRouteEmun(Object.values(obj), target);
          if (result) {
            return result;
          }
        } else if (obj === target) {
          // 找到了目标数据，返回它所在的子数组
          return obj;
        }
      }
    
      // 没有找到目标数据，返回null
      return null;
}

/**
 * @tag 监听绑定元素的变化 并获取数据
 */
export const getReset = {
  // 指令的挂载 
  bind: function (el, binding, vnode, oldVnode) {
    // 监听绑定元素
    resizeObserve.observe(el);
    weakMap.set(el, { callback: binding.value, arg: binding.arg })
  },
  unbind: function (el, binding, vnode, oldVnode) {
    resizeObserve.unobserve(el);
  }
}

// vue指令：只有数字输入
function onInput(el, ele, binding, vnode) {
  function handle() {
    // 只保留数字
    ele.value = ele.value.replace(/[^\d]/g, '');
  } 

  return handle
}

export const numberInput = {
    bind(el, binding, vnode) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
        ele.addEventListener('input', onInput(el, ele, binding, vnode), false);
    },
}

// vue指令 不能输入中文
function noInputChinese(el,ele,binding,vnode){
  function handle(){
    // 不保留中文
    ele.value = ele.value.replace(/[\u4E00-\u9FA0]/g,'');
  }

  return handle;
}

export const chineseInput = {
  bind(el,binding,vnode){
    const ele = el.tagName === 'INPUT'? el:el.querySelector("input");
    ele.addEventListener('input', noInputChinese(el, ele, binding, vnode), false);
  }
}


//获取对应的城市code
export function getCitycode(ev){
	let valText = ev.split(',')
    let codeArray = []
	if(valText[0]=="上海市" || valText[0]=="天津市" || valText[0]=="北京市" || valText[0]=="重庆市"){
		Mdirectly(valText,res=>{
			codeArray = res
		})
		
	}else{
		for(var i in citYvalue){
			if(citYvalue[i].label==valText[0]){
				codeArray.push(citYvalue[i].value)
				let cityList =  citYvalue[i].children
				for(var j in cityList){
					if(cityList[j].label==valText[1]){
						codeArray.push(cityList[j].value)
						let streeList = cityList[j].children 
						for(var h in streeList){
							if(streeList[h].label == valText[2]){
								codeArray.push(streeList[h].value)
							}
						}
					}
				}
			}
		}
	}
   return codeArray
	// console.log("换区对应的code",codeArray)
}

export function  Mdirectly(val,callback){
	
	if(val[0]=="上海市" || val[0]=="天津市" || val[0]=="北京市" || val[0]=="重庆市"){
		let list = []
		for(var i in citYvalue){
			if(citYvalue[i].label == val[0]){
				let listC = citYvalue[i].children
				 for(var j in listC){
					 let listZ = listC[j].children
					 for(var h in listZ){
						 list.push({p:citYvalue[i].label,pcode:citYvalue[i].value,c:listC[j].label,ccode:listC[j].value,z:listZ[h].label,zcode:listZ[h].value})
					 } 
				 }
			}
		}
		for(var n in list){
			if(list[n].z==val[1]){
				callback([list[n].pcode,list[n].ccode,list[n].zcode])
				return 
			}
		}
	}
}

// 大屏使用方法==============================================
/**
 * @tag 唯一的uid
 * @param { 长度 } len 
 * @param { 基数 } radix 
 * @returns 
 */
export function getUid(len, radix){
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | (Math.random() * radix)];
    }
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
};

/**
 * 设置 请求完打点之后的数据
 * @param { 打点请求之后的数组 数组类型 } data 
 * @param { 用户自定义内容 } meta 
 * @returns '返回一个数组'
 */
export function setMarkerData(data,meta = {}){
  let list = [];
  try{
    list = [...data];
  }catch{}
  return list.map(item=>{
    return {
      uid:getUid(32,10),
      // policeType:store.state.screenPoliceType,
      province:!!!item.areaAddress?'':item.areaAddress.split(',')[0],
      areaAddress:item.areaAddress,
      codeAddress:item.codeAddress,
      projectId:item.projectId,
      projectIds:item.projectIds,
      number:item.number,
      longiLatitude:item.latitudeLongitude,
      meta:meta,    // 用户自定义内容
    }
  })
}

/**
 * @tag 判断参数是否必须放入
 * @param { 参数的名字 } key 
 */
export function mustArg(key){
  throw new Error(`${key}该参数必须传`)
}

/**
 * 尖谷平峰
 */
export function filText(ev){
  let mealType = store.state.conText.mealType
    for(var i in mealType){
       if(mealType[i].val==ev*1){
          return  `<span style="color:${mealType[i].color}">${mealType[i].title}</span>`  
       } 
    }  
}

//排序按Key值
export function compareFun(prop){
  return function (obj1, obj2) {
    var val1 = obj1[prop];
    var val2 = obj2[prop];
    if (val1 < val2) {
        return -1;
    } else if (val1 > val2) {
        return 1;
    } else {
        return 0;
    }
  }
} 

//导入数据
export function exportData(keyVal,event,resolve,reject,callback){
  // 拿取文件对象
  var f = event
  // 用FileReader来读取
  var reader = new FileReader()
  // 重写FileReader上的readAsBinaryString方法
  FileReader.prototype.readAsBinaryString = (f)=>{
    var binary = ''
    var wb // 读取完成的数据
    var outdata // 你需要的数据
    var reader = new FileReader()
    reader.onload = (e) => {
     
      // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
      var bytes = new Uint8Array(reader.result)
      var length = bytes.byteLength
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      // 接下来就是xlsx了，具体可看api
      wb = XLSX.read(binary, {
        type: 'binary'
      })
      outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
      // 自定义方法向父组件传递数据

      if(outdata.length >= 5001){
          this.$message.warning("不能超过五千条")
          reject('无法上传')
        }else{
          let  serialNos = outdata.map(item=>item[keyVal]);
          callback(serialNos)
        
          reject('未超过限制')
        }
    }
    reader.readAsArrayBuffer(f)
  }
  reader.readAsBinaryString(f)
}


export default {
    numberInput,
    chineseInput,
    getReset,
    checkTarget,
    downloadFile,
    singleton,
    setMethod,
    getDate,
    getUid,
    setMarkerData,
    mustArg,
    filText
};