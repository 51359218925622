<template>
  <div id="MyRoot" ref="root" style="--inputHeight:39px;--numberHeight:39px;--numberTop:2px">
    <el-input autocomplete="off"
      :style="{'width':width}"
      class="block-input"
      v-if="!isNumber"
      :type="type"
      :disabled="disabled"
      :value="value"
      :maxlength="maxLength"
      :minlength="minLength"
      :placeholder="placeholder"
      :suffix-icon="suffixIcon"
      :prefix-icon="prefixIcon"
      :rows="rows"
      :autosize="autoSize"
      :show-password="showPassword"
      @input="getVal"
      v-model.trim="dataValue"
      :min="min" :max="max"
    >
    <template v-if="appendText != 'none'" slot="append">
      {{ appendText }}
    </template>
    </el-input>
    <el-input-number v-else class="block-number" :style="{'width':width}" v-model="dataValue" :min="min" :max="max" :step="step" :step-strictly="stepStrictly" 
    :disabled="disabled" @change="getValNum" :controls="controls" :precision="precision" :controls-position="controlsPosition" :placeholder="placeholder"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  props:{ 
    inputKey:{
      type:String,
      default:()=>'default'
    },
    type:{
      type:String,
      default:()=>'text'
    },
    appendText:{
      // 后缀
      type:String,
      default: () => "none"
    },
    hegiht:{
      type:String,
      default:()=>'33px'
    },
    numberTop:{
      type:String,
      default: () =>'2px'
    },
    numberHeihgt:{
      type:String,
      default: () =>'auto'
    },
    width:{
      type:String,
      default:()=>'200px'
    },
    disabled: {
      type:Boolean,
      default: () =>false
    },
    value:{
      type:[String,Number],
      default:() =>''
    },
    maxLength: {
      type:Number,
      default: () => 999
    },
    minLength: {
      type:Number,
      default:()=>-999
    },
    placeholder: {
      type:String,
      default:()=>''
    },
    size:{ 
      type:String,
      default: () => 'small'
    },
    prefixIcon:{
      type:String,
      default: () =>''
    },
    suffixIcon:{
      type:String,
      default: () => ""
    },
    rows:{
      type:Number,
      default: () => 2
    },
    autoSize:{
      type:Boolean,
      default: () => false
    },
    showPassword:{
      type:Boolean,
      default: () => false
    },
    min:{
      type:Number,
      default: () => -Infinity
    },
    max:{
      type:Number,
      default:()=>Infinity
    },
    step:{
      type:Number,
      default: () => 1
    },
    controls:{
      type:Boolean,
      default: () =>true
    },
    controlsPosition:{
      type: String,
      default: () => ''
    },
    stepStrictly:{
      type:Boolean,
      default: () =>false
    },
    precision:{
      type:Number,
      default: () => 0
    }
  },
  watch:{
    value(newData,preData){
      this.dataValue = newData;
    }
  },
  created(){
    this.dataValue = this.value;  
    if(this.type == 'inputNumber')this.isNumber = true;
    this.$nextTick(()=>{
      try{
        this.$refs.root.style.setProperty('--inputHeight',this.hegiht)
      }catch{}
      try { 
        this.$refs.root.style.setProperty('--numberHeight',this.hegiht)
      }catch{}
      try{
        this.$refs.root.style.setProperty('--numberTop',this.numberTop)
      }catch{}

    })
  },
  data() {
    return {
      dataValue:'',
      isNumber:false,// 是否打开element 计数器
    }
  },
  methods: {
    // 获取数据
    getVal(value){
      this.$emit('getVal',{
        key:this.inputKey,
        value:value
      })
    },
    getValNum(currentValue, oldValue){
      console.log('=============')
      this.$emit('getVal',{
        key:this.inputKey,
        value:currentValue,
        oldValue:oldValue
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .block-input{
    // background-color: red;
  }

  ::v-deep .el-input__inner{
    height: var(--inputHeight);
  }
  ::v-deep .el-input-number__decrease, .el-input-number__increase{
    top:var(--numberTop);
    height: var(--numberHeight);
  }

  ::v-deep .el-input-number__increase {
    top:var(--numberTop);
    height: var(--numberHeight);
  }

  .block-number{

  }
</style>