<template>
  <div>
    <div style="margin-bottom:10px">
      <slot></slot>
    </div>
    <el-table ref="putTable" :data="tableData" :height="tableHeight" :width="tableWidth" :stripe="stripe" :border="border"
      :header-cell-style="{ background: 'rgb(229, 235, 248)', 'color': 'rgb(77, 92, 114)' }" :size="Tablesize" :fit="fit"
      :showHeader="showHeader" :span-method="arraySpanMethod" :highlightCurrentRow="highlightCurrentRow"
      @selection-change="selectionChange">
      <template v-for="(item, index) in columns">
        <!--通过render函数返回的自定义-->
        <template v-if="!!item.render">
          <el-table-column :prop="item.prop" :label="item.label" :width="item.width" :header-align="item.headerAlign"
            :align="item.align" :fixed="item.fixed" :min-width="item.minWidth" :show-overflow-tooltip="item.tooltip" :sortable="item.sortable">
            <template slot-scope="scope">
              <custom :render="item.render" :row="scope.row" :index="scope.$index" />
            </template>
          </el-table-column>
        </template>
        <!-- 纯文本 -->
        <template v-else>
          <el-table-column :key="index" :prop="item.prop" :label="item.label || '--'" :width="item.width"
            :header-align="item.headerAlign" :align="item.align" :fixed="item.fixed" :min-width="item.minWidth"
            :type='item.type' :show-overflow-tooltip="item.tooltip" :sortable="item.sortable"/>
        </template>
      </template>
    </el-table>
    <Paging @getPage="getPage" :total="page.total" :pageNo="page.current" :size="page.size" />
  </div>
</template>
<script>
import custom from './custom.vue'
import api from '@/utils/config'
import { requestController } from '@/utils/http'
export default {
  components: {
    custom
  },
  props: {
    cloumsObj: {
      // 表格对象
      type: Object,
      default: () => { }
    },
    method: {
      //  请求方式
      type: String,
      default: () => 'post'
    },
    columns: {
      // 渲染表格数据
      type: Array,
      default: () => []
    },
    action: {
      // 请求url
      type: String,
      default: () => ''
    },
    params: {
      // 请求参数
      type: Object,
      default: () => { }
    },
    tableHeight: {
      // 表格高度
      type: Number,
      default: () => 230
    },
    tableWidth: {
      // 表格宽度
      type: Number,
      default: () => 230
    },
    stripe: {
      // 显示斑马纹
      type: Boolean,
      default: () => false
    },
    border: {
      // 是否有纵向边框
      type: Boolean,
      default: () => false
    },
    Tablesize: {
      // table的尺寸
      type: String,
      default: () => 'small'
    },
    fit: {
      // 列宽是否自动撑开
      type: Boolean,
      default: () => true
    },
    showHeader: {
      // 是否显示表头
      type: Boolean,
      default: () => true
    },
    highlightCurrentRow: {
      // 是否高亮当前行
      type: Boolean,
      default: () => false
    },
    dataArr: {
      // 传入的数据
      type: Array,
      default: () => []
    },
    spanMethod: {
      // 合并单元格方法
      type: Function,
      default: () => 'noneSpanMethod'
    },
  },
  data() {
    return {
      serve: {},
      page: {
        current: 0,
        size: 20
      },
      pageParam: {},
      tableData: [],
      notParam: []
    };
  },
  created() {
    // 是否传入数据 如果传入则 进行配置
    if (this.dataArr.length != 0) this.tableData = this.dataArr;

    this.search();
  },
  methods: {
    // 获取当前表格的数据
    getTableData() {
      return this.tableData;
    },
    // 表格多选
    selectionChange(val) {
      this.$emit('selection-change', val);
    },
    getPage(ev) {
      this.page.current = ev.pageNo;
      this.page.size = ev.pageSize;

      this.search('page', this.notParam);
    },
    // 表格请求
    getPageList(param, method) {
      if (method == 'post') {
        this.page.param = param;
      }

      requestController(this.action, method, this.page, res => {
        if (!!!res.data || res.data.length == 0) {
          this.tableData = [];
          return;
        } else {
          this.tableData = res.data;
        }

        if (!!!res.data.records || res.data.records.length == 0) {
          this.tableData = [];
          return;
        } else {
          this.tableData = res.data.records;
        }
        this.page.total = res.data.total;
      })
    },
    /**
     * 表格搜索
     * @param { 'page' 分页时查询 'reset' 重置 默认充值不充值显示的页面参数 'resetNone' 重置时会将param的数据一起重置 页面一起 notParam有数据优先以notParam为准 默认为 'none' param 传与不传默认都是搜索 传入的参数不会被更改成为默认参数 } param
     * @param { 重置时对应字段不重置 数组类型 默认值为'none'} notParam
     * @param { 请求方式 默认为post } method
     */
    search(param = 'none', notParam = [], method = 'post') {
      let isParam = this.params;

      this.notParam = notParam;

      if (param != 'page') {
        // 重置
        this.page = {
          total: 0,
          current: 0,
          size: 20
        }
      }

      if (param == 'reset' || param == 'resetNone') {
        let paramCopy = '';

        if (param == 'resetNone')
          paramCopy = this.params;
        else
          paramCopy = JSON.parse(JSON.stringify(this.params));

        for (let key in this.params) {
          if (notParam.includes(key)) {

          }
          else
            paramCopy[key] = null;
        }

        isParam = paramCopy;
      } else if (param != 'none' && param != 'page') {
        isParam = param;
      }

      if (param != 'page') this.page.current++;

      this.getPageList(isParam, method);
    },
    // 合并单元格
    arraySpanMethod(item) {
      let str = this.spanMethod(item);

      if (str == 'noneSpanMethod') {

      } else {
        return str;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
}

::v-deep .el-table thead {
  background-color: rgb(229, 235, 248) !important;
}
</style>