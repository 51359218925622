const configList = {
   selectBtn: [
      { title: "近一周", val: 1 },
      { title: "近一月", val: 2 },
      { title: "近一年", val: 3 },
   ],
   orderType: [
      { title: "待启动", val: 1, color: "#ff55ea" },
      { title: "充电中", val: 2, color: "#409EFF" },
      { title: "充电结束", val: 3, color: "#07C160" },
      { title: "充电失败", val: 4, color: "#ff0000" },
      { title: "取消充电", val: 5, color: "#aaaaff" }
   ],
   chargingType: [
      { title: "固定时长", val: 1, color: "#000000" },
      { title: "金额扣费", val: 2, color: "#409EFF" },
      { title: "充满自停", val: 3, color: "#16A077" }
   ],
   accountTypeList:[
	   { title: "运营", val: 1, color: "#409EFF" },
	   { title: "工厂", val: 2, color: "#07C160" },
	   { title: "代理商", val: 3, color: "#07C160" },
	   { title: "工程商", val: 4, color: "#aaaaff" },
	   { title: "充电商", val: 5, color: "#000000" }
   ],
   clientTypeList:[
	   { title: "PC", val: 1, color: "#ff55ea" },
	   { title: "APP", val: 2, color: "#409EFF" },
	   { title: "APPLET", val: 3, color: "#07C160" },
	   { title: "其它", val: 4, color: "#aaaaff" },

   ],

   selectionOption:[
      { val:1, title:'已安装'  },
      { val:0, title:'未安装' }
   ],
   activationList: [
      { val: 0,  title: "未激活" },
      { val: 1,  title: "已激活" }
   ],
   moneyType:[
      { val: 1,  title: "按指定时长充电" },
      { val: 2,  title: "按指定金额充电" },
      { val: 3,  title: "充满自停" },
   ],
   mealType:[
      { val: 0,  title: "尖", electricVal:0.5, serviceVal:0.5, color:"#2C4DF0" },
      { val: 1,  title: "峰", electricVal:0.5, serviceVal:0.5, color:"#FF3D00" },
      { val: 2,  title: "平", electricVal:0.5, serviceVal:0.5, color:"#3AE2CE" },
      { val: 3,  title: "谷", electricVal:0.5, serviceVal:0.5, color:"#606266" },
   ],
   electricityCate:[
      { val:"DC",  title: "直流-DC" },
      { val:"AC",  title: "交流-AC" },
   ],
   speedCate:[
      { val:"SLOW",  title: "慢充" },
      { val:"FAST",  title: "快充" },
      { val:"SUPER",  title: "超充" }
   ],
   oderTypeList:[
      { val:0,  title: "单车充电" , color:"#844EF0"},
      { val:1,  title: "汽车充电" , color:"#209AA0"},
      
   ]
}
module.exports = {
   conText: configList
}
