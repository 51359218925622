import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './utils/route'
import store from './utils/store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import JsonViewer from 'vue-json-viewer'
import Treeselect from "@riophae/vue-treeselect";

import VScaleScreen from 'v-scale-screen'

// 引用自定义组件
import modelbox from './components/block-modelbox'
import Paging from './components/block-page/index.vue'
import TableBlock from './components/block-table/index.vue'
import TableBlockNew from './components/block-table-new/index.vue'
import MyInput from './components/components-block/block-input.vue'
import MyBtn from './components/components-block/block-btn.vue'
import MyForm from './components/block-from/index.vue'

// 引入自定义指令
import { getReset } from './utils/utils'

Vue.config.productionTip = false;

// 引入ui库
Vue.use(ElementUI);

// 引入官方状态共享
Vue.use(Vuex);

// 引入自适应插件
Vue.use(VScaleScreen)

// 挂载全局模态框
Vue.prototype.$ModelBox = modelbox;

// 树形选择器
Vue.component('Treeselect',Treeselect)

// 分页
Vue.component('Paging',Paging);
// 表格
Vue.component('TableBlock',TableBlock);
// 新的表格
Vue.component('TableBlockNew',TableBlockNew);
// input框
Vue.component('MyInput',MyInput);
// btn按钮
Vue.component('MyBtn',MyBtn);
// 简易表单
Vue.component('MyForm',MyForm);
//json美化工具
Vue.use(JsonViewer);

//过滤器
Vue.filter('Accuracy',function(value){
  return  value.toFixed(4);
})

//截取前两位
Vue.filter('JqE',function(value){
  if(!!value){
    let text =  value.substr(0,2) 
    return  text;
  }
  return ""
})

Vue.filter('JHs',function(value){
  if(!!value){
    let text =  value.substr(value.length-5) 
    return  text;
  }
  return ""
})



// 指令
// 监听元素大小变化并获得对应参数
Vue.directive('reset', getReset);

// 全局utils
import utils /*{ numberInput }*/ from './utils/utils.js';
// vue指令
Vue.directive('number-input', utils.numberInput);
Vue.directive('chinse-input',utils.chineseInput)

//下拉选项标签不可删除
Vue.directive('defaultSelect', {
  componentUpdated(el, bindings) {
    const [defaultValues] = bindings.value
    const dealStyle = function (tags) {
      // 因为不可删除原有值，所以原有值的index是不会变的，也就是将前n个tag的close隐藏掉即可。n即已有值的长度defaultValues.length
      tags.forEach((el, index) => {
        if (
          index <= defaultValues.length - 1 &&
          ![...el.classList].includes('select-tag-close-none')
        ) {
          el.style.display = 'none' // close 图标隐藏掉
        }
      })
    }
    // 设置样式隐藏close icon
    const tags = el.querySelectorAll('.el-tag__close')
    if (tags.length === 0) {
      // 初始化tags为空处理
      setTimeout(() => {
        const tagTemp = el.querySelectorAll('.el-tag__close')
        dealStyle(tagTemp)
      })
    } else {
      dealStyle(tags)
    }
  }
})



// 创建vue
const app = new Vue({
  el:'#app',
  router,
  store,
  render:(h)=>h(App),
  beforeCreate(){
    Vue.prototype.$bus = this;
  }
}).$mount()

// 放入vue的实例对象 在全局
window.app = app;

// 放入地图key
window.mapKey = {
  AutoNaviKey: '3b592008854f47146c257ecc6e5b0dc5',     // 高德key
  TencentKey:'ZSOBZ-5LCK3-OOT3Z-3BBHJ-EKHKQ-ZXBQW',       // 腾讯key
  BaiduKey:'bKGEmLs4E9SGEGQ4NIxcfLESpcrLK5Bg',         // 百度key
}