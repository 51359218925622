<template>
  <div class="root">
    <el-button 
      :type="type"
      :size="size"
      :plain="plain"
      :round="round"
      :circle="circle"
      :loading="loading"
      :icon="icon"
      :autofocus="autofocus"
      @blur="isblur"
      @click="isclick" 
    >{{value}}<slot></slot></el-button>
  </div>
</template>

<script>
export default {
  props:{
    type:{
      type:String,
      default:()=>''
    },
    value:{
      type: String,
      default: () => ""
    },
    size:{
      type:String,
      default: () => "small"
    },
    plain:{
      type:Boolean,
      default:()=> false
    },
    round:{
      type:Boolean,
      default: () => false
    },
    circle:{
      type:Boolean,
      default: () => false
    },
    loading:{
      type:Boolean,
      default: () => false
    },
    disabled: {
      type:Boolean,
      default: () => false
    },
    icon:{
      type:String,
      default:()=>''
    },
    autofocus:{
      type: Boolean,
      default: () => false
    },
    blur:{
      type:Boolean,
      default: () => false
    },
    click:{
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    isblur(){
      if(!this.blur)return;

      this.$emit('blur');
    },
    isclick(){
      if(!this.click)return;

      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>
  // .my-btn{
  //   @include layout-flex();
  //   justify-content: center;
  //   align-items: center;
  // }
</style>