<template>
  <div id="app">
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      transitionName:'app-root'
    }
  },
  created() {

  },
  mounted() {
    const setSize = () => {
      console.log('更改宽度')
      this.$store.commit('setWindowSize', {
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    setSize();
    window.addEventListener('resize', setSize);
  },
  watch:{
    '$route'(newValue,oldValue){
      let str = newValue.path.toLowerCase();
      if(str.includes('screen')){
        this.transitionName = 'screen';
      }
    }
  }
}
</script>

<style lang="scss">
$marginRight: 15px;

// html {
//   font-size: 10vw;
// }

#app {
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}
html{
  // font-size: 16px;
}

.common-display {
  display: flex;
  justify-items: center;
}

.common-width {
  width: 100%;
}

.common-widthhalf {
  width: 50%;
}

.common-height {
  height: 100%;
}

.common-flex {
  display: flex;
}

.common-flexw {
  flex: 1;
}

.common-textCenter {
  text-align: center;
}

.common-space-around {
  display: flex;
  justify-content: space-around;
}

.common-space-between {
  display: flex;
  justify-content: space-between;
}

.common-items-center {
  display: flex;
  align-items: center;
}

.common-relatives {
  position: relative;
}

.common-absolute {
  position: absolute;
}

.common-wrap {
  flex-wrap: wrap;
}

.common-top {
  top: 0;
}

.common-bottom {
  bottom: 0;
}

.common-left {
  left: 0;
}

.common-right {
  right: 0;
}

.common-pading {
  padding: 0 20px;
  box-sizing: border-box;
}

.searchBlock {
  margin-right: 10px;
}

.common-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  left: 0;
  bottom: 0;
  transform: scaleY(0.1);
}

.inputBlock {
  margin-right: 10px;
  width: 150px
}


.disable {
  color: #B4B4B4;
  font-size: 14px;
}

.open {
  color: #409EFF;
  font-size: 14px;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #F0F4FC;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #E5EBF8;
}


/*表单距离下边的距离*/
.el-form-item {
  margin-bottom: 21px !important;
}

/*全局修改表头*/
/*全局修改表头*/
.el-table th.el-table__cell {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: #E5EBF8!important;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #4D5C72;
    letter-spacing: 0;
    height:42px;

}

/* 全局修改textarea样式*/
.el-textarea .el-textarea__inner {
  resize: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.el-input .el-input__inner::-webkit-input-placeholder {
  color: #C0C4CC;
  font-weight: 100;

}

.el-textarea .el-input__count {
  background-color: rgba(0, 0, 0, 0) !important;
}

.el-textarea .el-textarea__inner::-webkit-input-placeholder {
  /* color: red; */
  font-weight: 500;
}

.el-textarea .el-input__count {
  bottom: -5px !important;
  background-color: rgba(0, 0, 0, 0);
}


.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus,
.el-message-box__input input.invalid,
.el-message-box__input input.invalid:focus {
  border-color: rgba(245, 180, 180, 0.7);
}

.el-input .el-form-item__error {
  border-color: rgba(245, 180, 180, 0.3) !important;
}

.el-popconfirm__main {
  margin-bottom: 10px !important;
}

.el-loading-spinner .el-icon-loading {
  font-size: 40px;
}

.el-loading-mask {
  background-color: rgba(255, 255, 255, 0) !important;
}

.el-dialog__body {
  border-top: 0px solid #DCDFE6;
  padding: 10px 20px 24px 20px !important;
}

.el-dialog__header {
  display: flex;
  align-items: center;
  padding: 20px !important;
}

.el-dialog__headerbtn {
  top: 25px !important;
}

/*个人中心*/
.popperClass {
  // background-image: url("./assets/custom-image/information.svg")!important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 0 !important;
  border-radius: 4px !important;
}

.operate {
  ::v-deep .el-button--mini {
    padding: 5px 8px;
  }
}

.popDeleteW {
  width: 300px !important;
}

.search-public {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 15px;

  .search-box {
    @include layout-flex();

  }

  .search-input {
    @include layout-flex();
    align-items: center;

    .txt {
      margin-right: $marginRight;
      margin-left: 10px;
    }
  }

  .search-one {
    // @extend .search-input;
    margin-left: 0;
    @include layout-flex();
    align-items: center;

    .txt {
      margin-right: $marginRight;
      white-space: nowrap;
    }
  }

  .search-toolps {
    @include layout-flex();
    margin-left: $marginRight;
    align-items: center;
  }
}

.app-root-enter-active,
.app-root-leave-active {
  transition: all 1s;
  position: absolute;
  animation: isOpacity 1.2s ease-in;
}

.app-root-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.app-root-enter {
  opacity: 0;
  transform: translateX(100%);
}

@keyframes isOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

	//页面灰色
	.aldaori{
		filter: grayscale(100%); 
		-webkit-filter: grayscale(100%); 
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%); 
		-o-filter: grayscale(100%); 
		filter: url("data:image/svg+xml;utf8,#grayscale"); 
		filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1); 
		-webkit-filter: grayscale(1);
	}



</style>