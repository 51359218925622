
//配置测试还是生产
let mark = true //true表示生产  false//代表测试  

let pUrl = "https://pro.brjxf119.com/api"       //生产
let tUrl = "http://yunen.test.brjkj.cn:30000"   //测试

let pTwocode = 'https://pro.brjxf119.com'                    //生产二维码域名
let tTwocode = 'https://guanglukc.com/test'                  //测试二维码域名

// 接口前缀配置
export const api = {
  httpUrl: mark ? pUrl:tUrl,                                   // 接口域名
  codeUrl: mark ? pTwocode:tTwocode,                           // 二维码接口域名
  login:   'auth-service',                                     // 登录前缀
  other:   'charging-web-service',                             // 非登录前缀
  imgName: 'public-resource',                                  // 上传和浏览图片的bucketName
  imgUser: 'objectName',                                       // 用户管理上传和浏览图片的bucketName
}


// 常用正则配置
//常用的正则表达式配置
export const rules = {
  NONEMPTY: /^[\s\S]*.*[^\s][\s\S]*$/,                                                               //非空验证
  PHONE: /^1[3456789]\d{9}$/,                                                                        //手机号验证
  EMAIL: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,                       //邮箱验证
  IDCARD: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,    //身份证正则
  PASSWORD: /^[a-z0-9A-Z]{8,16}$/,                                                                   //密码的正则
  CHINESE: /^[\u4E00-\u9FA5]+$/,                                                                     //只允许输入中文 
  POSITVIE: /^\d+$/,                                                                                 //大于等于0的正整数  
  ENGCHENHSH: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/                                         //匹配6~12位由数字和26个英文字母混合而成的密码                                                              
}


// 接口配置
const interfaceOption = {


  // 上传图片
  UPLOADINGAS: "/minio-service/minio/uploadSingle?bucketName=" + api.imgName,                     //上传图片单张
  NEWUPLOADINGAS: "/minio-service/deviceInstall/uploadSingle",                                    //上传图片单张
  UPLOADINGMO: "minio-service/minio/uploadMultipleFile?bucketName=" + api.imgName,                //上传图片多张
  SHOWIMG: "/minio-service/minio/preview",                                                        //预览图片
  NEWSHOWIMG: "/minio-service/deviceInstall/getPreviewUrl?objectName="+api.imgName,               //浏览单张                              //预览图片
  SHOWIMGS: "/minio-service/minio/previewBatch",                                                  //预览图片多
  NEWSHOWIMGS: "/minio-service/minio/previewBatch",                                               //预览图片多

  // 下载模板
  DOWNTEMPLATE: api.other + '/common/getPreviewUrl',                                              // 获取设备导入模板

  // 获取当前用户登录的路由菜单
  GETROUTEEMUN: api.other + '/role/getMenuButtonByChargersUserId',                                // 获取当前登录用户的菜单

  // 修改密码
  UPDATEPASSWORD: api.other + '/account/updatePassword',                                          // 修改密码

  // 通过经纬获得地址
  GETGEOGRAPHICLOACTION: api.other + '/common/getGeographicLocation',                             // 获取经纬地址

  // 获取银行总行 无分页
  GETBANKCODELIST: api.other + '/common/getBankCodeList',                                         // 获取银行总行

  // 发送验证码
  SENDCODE: api.other + '/account/sendCode',                                                         // 发送验证码

  // 忘记密码发送验证码
  SENDCODEPWD: '/charging-customer-service/accountManager/sendCode',                                                   // 忘记密码验证码

  //账户管理
  CHARGINGUSER: {
    POSTUSERPAGELIST: api.other + '/account/getChargersUserPage',                             //充电商用户分页列表
    ADDCHARGINGUSER: api.other + '/account/addChargersUser',                                  //新增充电商用户
    GETDISABLEUSER: api.other + '/account/disableChargersUser',                               //禁用或启用
    EXPORTCHARGINGUSER: api.other + '/account/exportChargersUser',                            //导出充电商用户
    RESETCHARGINGUSERPWD: api.other + '/account/resetChargersUserPassword',                   //重置充电商密码
    UPDATECHARGINGUSER: api.other + '/account/updateChargersUser',                            //更新充电商用户
    GETCHARGERSINFO: api.other + '/account/getChargersInfo',                                  //登录用户信息
    DELETECHARGERBYID: api.other + '/account/deleteById',                                     //删除充电商
    GETLISTBUACCOUNT: api.other + '/account/getListByAccount',                                //根据账号获取用户信息
    GETLISTBYNAME: api.other + '/account/getListByName',                                      //根据姓名获取用户信息
    UPLOADINGAS: api.other + "/account/uploadSingle",                                          //图片上传  
    GETUSERDEAIL: api.other + "/account/getChargersUser",                                      //获取用户详情
    CHECKREMOVEMEMBER: api.other + "/project/checkRemoveMember",                               //检查移除项目成员
    ACCOUNTRESETPWD: api.other + '/account/updatePwdByCode',                                           //忘记密码

  },


  // 项目管理 or 充电套餐管理
  PROJECT: {
    GETPROJECTLISTS: api.other + '/project/getProjectLists',                                            // 获取项目列表
    DELECTPROJECT: api.other + '/project/delete',                                                       // 删除项目
    PAGEDEVPOLICE: api.other + '/project/pageDevicePolice',                                             // 查询报警列表
    ADDBATCHDEVPOLICE: api.other + '/project/addBatchDevicePolice',                                     // 报警批量设置
    PROJECTMEMBERLIST: api.other + '/project/getProjectMemberList',                                     // 查询成员列表
    REMOVEMEMBER: api.other + '/project/removeMember',                                                  // 移除项目成员
    GETDEVICELINKAGEPAGE: api.other + '/project/getDeviceLinkagePage',                                  // 设备联动主表分页
    UPDATEPROEJCT: api.other + '/project/update',                                                       // 项目更新
    ADDPROJECT: api.other + '/project/addProject',                                                      // 增加项目
    INVITEMEMBER: api.other + '/project/inviteMember',                                                  // 邀请成员
    GETPROJECTMEMBERLIST: api.other + '/project/projectMemberList',                                     // 获取成员列表
    PAGEDEVICEBYPROJECT: api.other + '/project/pageDeviceByProject',                                    // 获取设备列表
    DEVICEDETAILS: api.other + '/project/device/details',                                               // 查看设备和项目详情
    GETTREESPACE: api.other + '/project/tree/spaceStructure',                                           // 获取树形列表
    SPACESTRUCTURE: api.other + '/project/spaceStructure',                                              // 获取施工图
    DEVICEUNBINDING: api.other + '/project/device/unbinding',                                           // 解绑设备
    REMOVEDEVICE: api.other + '/project/device/remove',                                                 // 移除设备
    UPDATESTRUCTURE: api.other + '/project/device/updateSpaceStructure',                                // 更新安装位置
    ADDSTRUCTURE: api.other + '/project/addStructure',                                                  // 新增树形菜单
    UPDATESTRUCTURETREE: api.other + '/project/updateStructure',                                        // 更新施工图菜单
    PRODUCTIONDRAWING: api.other + '/project/productionDrawing',                                        // 添加施工图
    DELETESPACESTRUCTUREBYID: api.other + '/project/deleteSpaceStructureById',                          // 根据id删除树菜单
    DELETEDEVICELINKAGE: api.other + '/project/deleteDeviceLinkage',                                    // 删除设备联动主表
    ADDORUPDATEDEVICELINKAGE: api.other + '/project/addOrUpdateDeviceLinkage',                          // 新增或更新联动设备主表
    GETDEVICELINKAGESUB: api.other + '/project/getDeviceLinkageSub',                                    // 根据id获取联动子表
    DELECTDEVICELINKAGESUB: api.other + '/project/deleteDeviceLinkageSub',                              // 删除设备联动子表
    CHECKDEVICELINKAGEADD: api.other + '/project/checkDeviceLinkageAdd',                                // 检测非根节点是否 可以添加子节点
    ADDORUPDATEDEVICELINKAGESUB: api.other + '/project/addOrUpdateDeviceLinkageSub',                    // 新增或更新设备联动子表
    GETDEVICELIST: api.other + '/project/getDeviceList',                                                // 获取所有可联动设备
    GETSTUCTURE: api.other + '/project/getStructure',                                                   // 联动 联动施工图菜单
    GETPROJECTLISTS: api.other + '/project/getProjectLists',                                            // 获取项目列表
    GETDEVICELINKAGESUBLIST: api.other + '/project/getDeviceLinkageSubList',                            // 获取设备子表信息
    PULLDOWNPROJECTALL: api.other + '/project/pullDown',                                                // 查询所有
    PULLDOWNPROJECT: api.other + '/project/getPullDownList',                                            // 查询项目
    GETDEVICESETMEAL: api.other + '/project/pageDeviceSetMealByProject',                                // 获取充电套餐设置
    SAVEDEVICEPOWERPRICE: api.other + '/project/saveDevicePowerPrice',                                  // 设置功率
    GETPOWERPRICELIST: api.other + '/project/getPowerPriceList',                                        // 获取功率列表
    GETPROJECTDETAIL: api.other + '/project/getProjectDetail',                                          // 查询项目详情
    TRANSFERPROJECT: api.other + '/project/transferProject',                                            // 项目转让
    GETPROJECTMEMBERSROLE: api.other + '/account/getProjectMembers',                                    // 普通角色成员
    GETDEVICEPORTLIST:api.other + '/deviceManager/getDevicePortList',                                   // 查看端口

    // 分润管理
    GETLISTBUPROJECTID: api.other + '/account/getListByProjectId',                                      // 获取充电商项目用户
    GETLISTBYCHARGERSID: api.other + '/account/getListByChargersId',                                    // 分润管理处 获取下拉的充电商用户
    GETTRANSFERCONFIGBYPORJECT: api.other + '/financeManager/transfer/getTransferConfigByProjectId',    // 分润管理 获取项目分润配置
    DELTRANSFERCONFIGBYOPRJECT: api.other + '/financeManager/transfer/deleteTransferConfigById',           // 分润管理 删除

    // 套餐管理
    PROJECTGSETMEAILL: api.other + '/project/getSetMealList',                                           // 获取充电套餐无分页
    SAVEDEVICESETMEAL: api.other + '/project/saveDeviceSetMeal',                                        // 设置设备套餐
    GETSETMEALPAGEPage: api.other + '/setMeal/getSetMealPage',                                          // 充电设备套餐查询有分页
    DELETESETMEAL: api.other + '/setMeal/deleteSetMeal',                                                // 删除套餐主表
    ADDSETMEAL: api.other + '/setMeal/addSetMeal',                                                      // 新增套餐
    CHECKSETMEAL: api.other + '/setMeal/getSetMeal',                                                    // 根据主表的id获取信息
    UPDATESETMEAL: api.other + '/setMeal/updateSetMeal',                                                // 更新套餐
    GETDEVICESETMEALPAGE: api.other + '/setMeal/getDeviceSetMealPage',                                  // 获取子表设备page
    REMOVEDEVICESETMEAL: api.other + '/setMeal/removeDeviceSetMeal',                                    // 移除设备套餐
    EXPORTDEVICESETMEAL: api.other + '/setMeal/exportDeviceSetMeal',                                    // 导出套餐信息
    GETSETMEALLISTBYSERIALNO: api.other + '/project/getSetMealListBySerialNo',                          // 根据设备序列号 获取套餐
    DELETESETMEALDETAIL: api.other + '/setMeal/deleteSetMealDetail',                                    // 删除指定套餐 
    DELETEPOWERSETMEAL: api.other + '/setMeal/deletePowerSetMeal',                                      // 删除套餐功率子表

  },

  //角色管理 OR 菜单管理
  USERMANAGE: {
    GETROLEPAGE: api.other + '/role/getRolePage',                                  //分页获取角色
    ENABLEDISABLE: api.other + '/role/enableDisable',                              //禁用启用角色
    ADDUSER: api.other + '/role/add',                                              //新增角色
    DELETEUSER: api.other + '/role/delete',                                        //删除角色
    GETROLEBYID: api.other + '/role/getRoleById',                                  //根据ID查询角色
    UPDATEUSER: api.other + '/role/update',                                        //更新角色
    GETROLESBYENTITY: api.other + '/role/getRolesByEntity',                        //查询角色
    GETMEUNBUTTONTREE: api.other + '/role/getMenuButtonByChargersUserId',          //获取当前用户菜单按钮
    GETMEUNROLEID: api.other + '/role/getMenuButtonByRoleId',                  //获取对应角色用户菜单按钮

    //菜单管理
    GETMENUTREE: api.other + '/menu/getMenuButtonTree',          //获取当前用户菜单按钮

  },
  //订单管理
  ORDERMANAGE: {
    GETORDERPAGE: api.other + '/orderManager/getOrderPage',                         //获取订单分页
    ORDERINFO: api.other + '/orderManager/getOrder',                                //获取订单详情
    EXPORTORDER: api.other + '/orderManager/exportOrder',                           //导出订单
  },

  // 设备管理
  DEVICE: {
    GETDEVICECHARGERSPAGE: api.other + '/deviceManager/getDeviceChargersPage',          // 设备分页
    GETDEVICEDETAIL: api.other + '/deviceManager/getDeviceDetail',                      // 设备详情
    UNBINDDEVICECHARGERS: api.other + '/deviceManager/unbindDeviceChargers',            // 解绑设备
    IMPORTDEVICECHARGERS: api.other + '/deviceManager/importDeviceChargers',            // 导入设备
    EXPROTDEVICECHARGERS: api.other + '/deviceManager/exportDeviceChargers',            // 导出设备
    GETSPACESTRUCTURETREE: api.other + '/deviceManager/getSpaceStructureTree',          // 获取项目位置区域
    INSTALLDEVICECHARGERS: api.other + '/deviceManager/installDeviceChargers',          // 安装设备
    UPDATEDEVICECHARGERS: api.other + '/deviceManager/updateDeviceChargers',            // 编辑设备
    REMOVEDEVICECHARGERS: api.other + '/deviceManager/removeDeviceChargers',            // 删除设备
    ADDDEVICECHARGERS: api.other + '/deviceManager/addDeviceChargers',                  // 添加设备
    GETDEVCHARGERPAGE: api.other + '/deviceManager/getDeviceChargersPage',              // 未激活设备列表 无分页
    REPLACEDEVICE: api.other + '/deviceManager/replaceDevice',                          // 换绑设备
    GETCHARGECONFI:api.other + '/deviceManager/getChargeConfig',                        // 查询充电配置
    UPDATECHARGECONFIG:api.other + '/deviceManager/updateChargeConfig',                 // 更新充电配置
  },

  // 财务管理
  fINANCE: {

    // 个人余额
    GETCHARGERSBALANCE: api.other + '/chargersBalance/getChargersBalance',                       // 查询个人余额

    // 充值
    WALLETRECHARGE: api.other + '/rechargeManager/walletRecharge',                              // 钱包充值
    GETORDERSTATUS: api.other + '/rechargeManager/getOrderStatus',                              // 查询订单状态
    SMSRECHARGE:api.other + '/rechargeManager/smsRecharge',                                     // 短信充值
    VOICERECHARGE:api.other + '/rechargeManager/voiceRecharge',                                 // 语音充值

    // 短信电话套餐
    GETLISTSV:api.other + '/financeManager/list/sv',                                                             // 获取短信语音套餐

    // 项目收入
    GETPROJECTBILLPAGE: api.other + '/financeManager/getProjectBillPage',                    // 项目总收入统计
    GETPROJECTORDERBYDEVICEPORT: api.other + '/financeManager/getProjectOrderByDevicePort',  // 项目端口收入统计

    //分成管理
    GETTRANSFERCONFIGPAGE: api.other + '/financeManager/transfer/getTransferConfigPage',                   //分成配置-列表
    ADDTRANSFERCONFIGPAGE: api.other + '/financeManager/transfer/addTransferConfigPage',                   //分成配置-添加
    DELETETRANSFERCONFIGBYID: api.other + '/financeManager/transfer/deleteTransferConfigById',             //分成配置-删除
    UPDATETRANSFERCONFIGPAGE: api.other + '/financeManager/transfer/updateTransferConfigPage',             //分成配置-更新
    GETLISTBYMYPROJECT: api.other + '/project/getListByMyProject',                                         //我的项目下拉

    GETPROJECTBILLPAGE: api.other + '/financeManager/getProjectBillPage',                    // 项目总收入统计
    GETPROJECTORDERBYDEVICEPORT: api.other + '/financeManager/getProjectOrderByDevicePort',  // 项目端口收入统计

    ADDORUPDATETRANSFERCONFIGPAGE: api.other + '/financeManager/transfer/addOrUpdateTransferConfigPage',   //分成配置-批量添加或更新
    GETTRANSFERCONFIGBYPROJECTID: api.other + '/financeManager/transfer/getTransferConfigByProjectId',     //获取充电商转账分成配置列表

    // 设备管理
    DEVICESERVICELIST: api.other + '/financeManager/deviceService/list',                                      // 设备服务费分页
    DEVICERECHARGE: api.other + '/rechargeManager/deviceRecharge',                                            // 设备续费
    GETDEVICELISTMEAL: api.other + '/financeManager/list/device',                                             // 获取套餐列表

    // 充值记录分页
    RECHARGERECORDPAGESEARCH: api.other + '/rechargeManager/rechargeRecordPageSearch',                         // 充值记录分页

    // 设备消费记录
    DEVICESERVICE: api.other + '/financeManager/deviceConsumeRecord/getPage',                                  // 设备服务费分页

    EXPORT: api.other + '/rechargeManager/export',                                                                // 导出充值记录
    DEVICESERVICEEXPORT: api.other + '/financeManager/deviceService/export',                                      // 导出设备服务费
    CONSUMERECORDEXPORT: api.other + '/financeManager/deviceConsumeRecord/export',                                // 导出设备消费记录
  },

  // 功率管理
  POWER: {
    POWERPRICEPAGE: api.other + '/powerPrice/getDevicePowerPricePage',                       // 功率设备分页
    GETPOWERPRICE: api.other + '/powerPrice/getPowerPrice',                                  // 根据主id获取功率信息
    GETPOWERPRICEPAGE: api.other + '/powerPrice/getPowerPricePage',                          // 获取功率分页
    UPDATEPOWERPRICE: api.other + '/powerPrice/updatePowerPrice',                            // 更新功率
    REMOVEDEVICEPOWERPRICE: api.other + '/powerPrice/removeDevicePowerPrice',                // 移除设备功率
    EXPORTDEVICEPOWERPRICE: api.other + '/powerPrice/exportDevicePowerPrice',                // 导出设备功率
    DELETEPOWERDETAIL: api.other + '/powerPrice/deletePowerPriceDetail',                     // 移除功率子表
    DELETEPOWERPRICE: api.other + '/powerPrice/deletePowerPrice',                            // 删除功率主表
    ADDPOWERPRICE: api.other + '/powerPrice/addPowerPrice',                                  // 增加功率      
  },

  LOGMANAGE: {
    RECORD: api.other + '/systemLog/getSystemPublishEventPage',                       //查询操作日志
  },


  // 资产管理
  PROPERTY: {
    GETPROJECTHISTORAM: api.other + '/financeManager/getProjectHistogram',                             // 项目收入柱形图
    GETCHARGERSBALANCE: api.other + '/financeManager/getChargersBalance',                              // 总收益情况
  },

  // 部门管理
  DEPARTMENT: {
    GETDEPARTTREE: api.other + '/depart/getDepartTree',                                       // 获取部门树形数据
    DELETE: api.other + '/depart/delete',                                                     // 删除部门
    UPDATE: api.other + '/depart/update',                                                     // 更新部门
    ADD: api.other + '/depart/add',                                                              // 增加部门
    GETCHARGERUSERLIST: api.other + '/depart/getChargersUserList',                               // 查看人员
  },

  // 充电桩大屏接口
  SCREEN:{
    // 充电概况 左边第一个
    GETDEVICESTATISTICS: api.other + '/screenManager/getDeviceStatistics',
    // 设备分类 左边第二个
    GETDEVICETYPERATIO: api.other + '/screenManager/getDeviceTypeRatio',
    // 设备报警概览 左边第三个
    GETDEVICEPOLICE:api.other + '/screenManager/getDevicePoliceStatistics',
    // 订单概况 右边第一个
    GETORDERSTATISTICSTODAY:api.other + '/screenManager/getOrderStatisticsToday',
    // 近七天订单数据 右边第二个
    GETORDERSTATISTICSHISTOGRAM:api.other + '/screenManager/getOrderStatisticsHistogram',
    // 充电七日 右边第三个
    GETELYSTATISTICSHISTOGRAM:api.other + '/screenManager/getElyStatisticsHistogram',
    // 项目打点
    GETPROJECTDISTRIBUTION:api.other + '/screenManager/getProjectDistribution',
    // 设备打点
    GETDEVICEDISTRIBUTION: api.other + '/screenManager/getDeviceDistribution',
  }
}
export default {
  api,
  rules,
  option: interfaceOption,
}